import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  url: string | null | undefined;
}

export default (): AlpineComponent<State> => ({
  url: undefined,
  change(event: Event) {
    if (event.target === undefined) {
      return;
    }

    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const file = event.target.files?.[0];

    if (file) {
      this.url = URL.createObjectURL(file);
    }
  },
  src() {
    if (!(this.$el instanceof HTMLImageElement)) {
      return;
    }

    if (this.url === undefined) {
      this.url = this.$el.src;
    }

    return this.url;
  },
});
