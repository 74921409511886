import { AlpineComponent } from "alpinejs";
import { z } from "zod";

import { call } from "../fetch";

type Added = {
  id: string;
  status: "ADDED";
  filename: string;
  file: File;
  labels: string[];
  created: Date;
  shared: boolean;
};

type Uploading = {
  id: string;
  status: "UPLOADING";
  filename: string;
  file: File;
  labels: string[];
  created: Date;
  shared: boolean;
};

type FailedUpload = {
  id: string;
  status: "FAILED_UPLOAD";
  filename: string;
  file: File;
  labels: string[];
  created: Date;
  shared: boolean;
};

type Saved = {
  id: string;
  status: "SAVED";
  filename: string;
  pk: number;
  url: string;
  labels: string[];
  created: Date;
  user: string;
  shared: boolean;
  share: string;
  hide: string;
  destroy: boolean;
};

type Working = {
  id: string;
  status: "WORKING";
  filename: string;
  pk: number;
  url: string;
  labels: string[];
  created: Date;
  user: string;
  shared: boolean;
  share: string;
  hide: string;
  destroy: boolean;
};

type Failed = {
  id: string;
  status: "FAILED";
  filename: string;
  pk: number;
  url: string;
  labels: string[];
  created: Date;
  user: string;
  shared: boolean;
  share: string;
  hide: string;
  destroy: boolean;
};

type Upload = Added | Uploading | FailedUpload | Saved | Working | Failed;

interface State extends Record<string | symbol, unknown> {
  files: Upload[];
  selected: Upload | undefined;
  upload(id: string): Promise<void>;
}

const RemoteFile = z.object({
  filename: z.string(),
  pk: z.number().int(),
  url: z.string(),
  labels: z.array(z.string()),
  created: z.coerce.date(),
  user: z.string(),
  shared: z.boolean(),
  share: z.string(),
  hide: z.string(),
  destroy: z.boolean(),
});

type RemoteFile = z.infer<typeof RemoteFile>;

const hydrate = ({
  filename,
  pk,
  url,
  labels,
  created,
  user,
  shared,
  share,
  hide,
  destroy,
}: RemoteFile): Saved => ({
  id: crypto.randomUUID(),
  status: "SAVED",
  filename,
  pk,
  url,
  labels,
  created,
  user,
  shared,
  share,
  hide,
  destroy,
});

const add = (file: File): Added => ({
  id: crypto.randomUUID(),
  status: "ADDED",
  filename: file.name,
  file,
  labels: [],
  created: new Date(),
  shared: false,
});

export default (data: unknown): AlpineComponent<State> => {
  const files =
    typeof data !== "string"
      ? []
      : RemoteFile.array()
          .default([])
          .parse(JSON.parse(atob(data)))
          .map(hydrate);

  return {
    files,
    selected: undefined,
    async upload(id: string): Promise<void> {
      const form = this.$refs["form"];

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return;
      }

      if (upload.status !== "ADDED" && upload.status !== "FAILED_UPLOAD") {
        return;
      }

      const body = new FormData(form);
      body.set("file", upload.file);

      this.files = this.files.map((x) => {
        if (x.id === upload.id) {
          return {
            ...upload,
            status: "UPLOADING",
          };
        }

        return x;
      });

      const response = await call(form.action, {
        body,
        headers: {
          Accept: "application/json",
        },
        method: "POST",
      });

      if (response.ok) {
        const parse = RemoteFile.safeParse(response.value);

        if (parse.success) {
          this.files = this.files.map((x) => {
            if (x.id === upload.id) {
              return {
                id: x.id,
                status: "SAVED",
                pk: parse.data.pk,
                filename: x.filename,
                url: parse.data.url,
                labels: parse.data.labels,
                created: parse.data.created,
                user: parse.data.user,
                shared: parse.data.shared,
                share: parse.data.share,
                hide: parse.data.hide,
                destroy: parse.data.destroy,
              };
            }

            return x;
          });

          return;
        }
      }

      this.files = this.files.map((x) => {
        if (x.id === upload.id && x.status === "UPLOADING") {
          return { ...x, status: "FAILED_UPLOAD" };
        }

        return x;
      });
    },
    async change(event: Event): Promise<void> {
      if (!(event.target instanceof HTMLInputElement)) {
        return;
      }

      const files = Array.from(event.target.files ?? []).map(add);

      event.target.value = "";

      this.files.unshift(...files);

      await Promise.all(files.map((x) => x.id).map(this.upload.bind(this)));
    },
    dragover(event: unknown): void {
      if (!(event instanceof DragEvent)) {
        return;
      }

      event.preventDefault();
    },
    async drop(event: unknown): Promise<void> {
      if (!(event instanceof DragEvent)) {
        return;
      }

      event.preventDefault();

      let files = Array.from(event.dataTransfer?.files ?? []).map(add);
      const input = this.$refs["input"];
      if (input && input instanceof HTMLInputElement && input.accept) {
        files = files.filter((file) => {
          const filetype = file.filename.split(".").pop();
          if (filetype) {
            return input.accept.includes(filetype);
          }
          return false;
        });
      }

      this.files.unshift(...files);

      await Promise.all(files.map((x) => x.id).map(this.upload.bind(this)));
    },
    async remove(id: unknown): Promise<void> {
      if (typeof id !== "string") {
        return;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return;
      }

      if (upload.status !== "SAVED" && upload.status !== "FAILED") {
        return;
      }

      const form = this.$refs["form"];

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const data = new FormData(form);
      const token = data.get("csrfmiddlewaretoken") ?? "";

      if (typeof token !== "string") {
        return;
      }

      this.files = this.files.map((x) => {
        if (x.id === upload.id) {
          return { ...upload, status: "WORKING" };
        }

        return x;
      });

      const response = await call(upload.url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
        method: "DELETE",
      });

      if (response.ok) {
        this.files = this.files.filter((x) => x.id !== id);

        if (this.selected?.id === id) {
          this.selected = undefined;
        }
      } else {
        this.files = this.files.map((x) => {
          if (x.id === upload.id) {
            return { ...upload, status: "FAILED" };
          }

          return x;
        });
      }
    },
    async retry(id: unknown): Promise<void> {
      if (typeof id !== "string") {
        return;
      }

      await this.upload(id);
    },
    canDelete(id: unknown): boolean {
      if (typeof id !== "string") {
        return false;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return false;
      }

      if (
        upload.status === "ADDED" ||
        upload.status === "UPLOADING" ||
        upload.status === "FAILED_UPLOAD"
      ) {
        return false;
      }

      return upload.destroy;
    },
    canRetry(id: unknown): boolean {
      if (typeof id !== "string") {
        return false;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return false;
      }

      if (upload.status === "FAILED_UPLOAD") {
        return true;
      }

      return false;
    },
    canHide(id: unknown): boolean {
      if (typeof id !== "string") {
        return false;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return false;
      }

      if (
        upload.status === "ADDED" ||
        upload.status === "UPLOADING" ||
        upload.status === "FAILED_UPLOAD"
      ) {
        return false;
      }

      if (upload.hide === "") {
        return false;
      }

      if (upload.shared) {
        return true;
      }

      return false;
    },
    canShare(id: unknown): boolean {
      if (typeof id !== "string") {
        return false;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return false;
      }

      if (upload.shared) {
        return false;
      }

      if (
        upload.status === "ADDED" ||
        upload.status === "UPLOADING" ||
        upload.status === "FAILED_UPLOAD"
      ) {
        return false;
      }

      if (upload.share === "") {
        return false;
      }

      return true;
    },
    async hide(id: unknown): Promise<void> {
      if (typeof id !== "string") {
        return;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return;
      }

      if (upload.status !== "SAVED" && upload.status !== "FAILED") {
        return;
      }

      if (!upload.shared) {
        return;
      }

      const form = this.$refs["form"];

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const data = new FormData(form);
      const token = data.get("csrfmiddlewaretoken") ?? "";

      if (typeof token !== "string") {
        return;
      }

      this.files = this.files.map((x) => {
        if (x.id === upload.id) {
          return { ...upload, status: "WORKING" };
        }

        return x;
      });

      const response = await call(upload.hide, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
        method: "PUT",
      });

      if (response.ok) {
        this.files = this.files.map((x) => {
          if (x.id === upload.id) {
            return { ...upload, status: "SAVED", shared: false };
          }

          return x;
        });
      } else {
        this.files = this.files.map((x) => {
          if (x.id === upload.id) {
            return { ...upload, status: "FAILED" };
          }

          return x;
        });
      }
    },
    async share(id: unknown): Promise<void> {
      if (typeof id !== "string") {
        return;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return;
      }

      if (upload.status !== "SAVED" && upload.status !== "FAILED") {
        return;
      }

      if (upload.shared) {
        return;
      }

      const form = this.$refs["form"];

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const data = new FormData(form);
      const token = data.get("csrfmiddlewaretoken") ?? "";

      if (typeof token !== "string") {
        return;
      }

      this.files = this.files.map((x) => {
        if (x.id === upload.id) {
          return { ...upload, status: "WORKING" };
        }

        return x;
      });

      const response = await call(upload.share, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
        method: "PUT",
      });

      if (response.ok) {
        this.files = this.files.map((x) => {
          if (x.id === upload.id) {
            return { ...upload, status: "SAVED", shared: true };
          }

          return x;
        });
      } else {
        this.files = this.files.map((x) => {
          if (x.id === upload.id) {
            return { ...upload, status: "FAILED" };
          }

          return x;
        });
      }
    },
    status(id: unknown, status: unknown): boolean {
      if (typeof id !== "string" || typeof status !== "string") {
        return false;
      }

      return this.files.some((x) => x.id === id && x.status === status);
    },
    label(id: unknown, label: unknown): boolean {
      if (typeof id !== "string" || typeof label !== "string") {
        return false;
      }

      return this.files.some(
        (x) => x.id === id && x.labels.some((l) => l === label),
      );
    },
    date(value: unknown): string {
      if (!(value instanceof Date)) {
        return "";
      }

      return value.toLocaleString();
    },
    select(value: unknown): void {
      if (typeof value !== "string") {
        return;
      }

      this.selected = this.files.find((x) => x.id === value);
    },
    async toggleLabel(id: unknown, label: unknown): Promise<void> {
      if (typeof id !== "string" || typeof label !== "string") {
        return;
      }

      const upload = this.files.find((x) => x.id === id);

      if (upload === undefined) {
        return;
      }

      if (upload.status !== "SAVED" && upload.status !== "FAILED") {
        return;
      }

      const form = this.$refs["form"];

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const data = new FormData(form);
      const token = data.get("csrfmiddlewaretoken") ?? "";

      if (typeof token !== "string") {
        return;
      }

      this.files = this.files.map((x) => {
        if (x.id === upload.id) {
          return { ...upload, status: "WORKING" };
        }

        return x;
      });

      const labels = upload.labels.includes(label)
        ? upload.labels.filter((x) => x !== label)
        : upload.labels.concat([label]);

      const response = await call(upload.url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": token,
        },
        method: "PUT",
        body: JSON.stringify({ ...upload, labels }),
      });

      if (response.ok) {
        const parse = RemoteFile.safeParse(response.value);

        if (parse.success) {
          this.files = this.files.map((x) => {
            if (x.id === upload.id) {
              return {
                id: x.id,
                status: "SAVED",
                pk: parse.data.pk,
                filename: x.filename,
                url: parse.data.url,
                labels: parse.data.labels,
                created: parse.data.created,
                user: parse.data.user,
                shared: parse.data.shared,
                share: parse.data.share,
                hide: parse.data.hide,
                destroy: parse.data.destroy,
              };
            }

            return x;
          });

          return;
        }
      }

      this.files = this.files.map((x) => {
        if (x.id === upload.id) {
          return { ...upload, status: "FAILED" };
        }

        return x;
      });
    },
  };
};
