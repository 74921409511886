import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  created: number[];
  existing: number[];
  removed: number[];
  show(): boolean;
  index?: number;
  prefix: string;
}

const succ = (xs: number[]): number => {
  if (xs.length === 0) {
    return 0;
  }

  return Math.max.apply(null, xs) + 1;
};

export default (prefix: unknown): AlpineComponent<State> => ({
  created: [],
  existing: [],
  removed: [],
  prefix: typeof prefix === "string" ? prefix : "",
  register() {
    const index = succ(this.existing);

    this.existing.push(index);

    return { index };
  },
  add() {
    const index = Math.max(
      succ(this.created),
      succ(this.existing),
      succ(this.removed),
    );

    this.created.push(index);

    this.$dispatch("input");
  },
  name(strings: string[], ...substitutions: string[]) {
    if (this.show()) {
      return strings.reduce((s, x, i) => {
        return (s +=
          substitutions[i] !== undefined ? `${x}${substitutions[i]}` : x);
      }, "");
    }

    return "";
  },
  remove() {
    if (typeof this["index"] !== "number") {
      return;
    }

    this.created = this.created.filter((x) => x != this["index"]);
    this.existing = this.existing.filter((x) => x != this["index"]);

    this.removed.push(this["index"]);

    this.$dispatch("input");
  },
  show() {
    if (this.index === undefined) {
      return false;
    }

    return this.created.concat(this.existing).includes(this.index);
  },
});
