import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  stageTwoCost: number;
  stageThreeCost: number;
  costPerMeasure: number;
  measureCount: number;
  baseCost: number;
  stageThreeMultiplier: number;
  furbnowMultiplier: number;
  VATMultiplier: number;
  totalMeasureCost(): number;
  stageTwoRCCost(): number;
  stageThreeRCCost(): number;
  updateCosts(): void;
}

export default (): AlpineComponent<State> => ({
  stageTwoCost: 0,
  stageThreeCost: 0,
  baseCost: 60000,
  costPerMeasure: 24000,
  measureCount: 0,
  stageThreeMultiplier: 1.3,
  furbnowMultiplier: 1.2,
  VATMultiplier: 1.2,

  init() {
    this.updateCosts();
  },

  updateCosts() {
    this.measureCount = document.querySelectorAll(".measure-item").length;
  },

  totalMeasureCost() {
    return this.costPerMeasure * this.measureCount;
  },

  stageTwoRCCost() {
    return this.baseCost + this.totalMeasureCost();
  },

  stageThreeRCCost() {
    return this.stageTwoRCCost() * this.stageThreeMultiplier;
  },

  stageTwoFurbnowCost() {
    return this.stageTwoRCCost() * this.furbnowMultiplier * this.VATMultiplier;
  },

  stageThreeFurbnowCost() {
    return (
      this.stageThreeRCCost() * this.furbnowMultiplier * this.VATMultiplier
    );
  },

  formatCurrency(value: number) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    }).format(value / 100);
  },
});
