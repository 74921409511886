import { AlpineComponent } from "alpinejs";
import { z } from "zod";

const ErrorRecord = z.union([
  z.record(z.string(), z.unknown()),
  z.array(z.unknown()),
]);

type ErrorRecord = z.infer<typeof ErrorRecord>;

interface State extends Record<string | symbol, unknown> {
  dirty: boolean;
  submit: boolean;
  errors: ErrorRecord;
}

export default (): AlpineComponent<State> => ({
  dirty: false,
  submit: false,
  errors: {},
  async go(event: SubmitEvent) {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    if (!(event.submitter instanceof HTMLButtonElement)) {
      return;
    }

    event.preventDefault();

    if (this.submit) {
      return;
    }

    const form = event.target.closest("form");
    if (!form) {
      return;
    }

    this.submit = true;

    if (form instanceof HTMLFormElement) {
      const body = new FormData(form);

      for (const [key, value] of body.entries()) {
        if (value instanceof File && value.size === 0) {
          body.delete(key);
        }
      }

      const response = await fetch(event.submitter.formAction, {
        body,
        headers: {
          Accept: "application/json",
        },
        method: "POST",
      });

      this.submit = false;

      if (response.status === 200) {
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          this.dirty = false;
          this.errors = {};
          return;
        }
      }

      if (response.status === 400) {
        this.errors = ErrorRecord.parse(await response.json());
      }
    }
  },
  error(key: unknown) {
    if (typeof key !== "string") {
      return;
    }

    const path = key.split(/\[|\]/);

    const find = (obj: ErrorRecord, keys: string[]) => {
      const [x, ...xs] = keys;

      if (x === undefined) {
        return obj;
      }

      const next = Array.isArray(obj) ? obj[parseInt(x, 10)] : obj[x];

      if (next === undefined) {
        return;
      }

      return find(ErrorRecord.parse(next), xs);
    };

    return find(this.errors, path) ?? "";
  },
});
