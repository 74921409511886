import "htmx.org";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

import "./dashboard/carbon";
import "./dashboard/cost";

// React Components
import "./react/availability-picker";

// Alpine Components
import address from "./assessment/address";
import ajax from "./forms/ajax";
import assignee from "./assignee";
import calendar from "./components/calendar";
import carousel from "./components/carousel";
import cover from "./suppliers/cover";
import datetimepicker from "./components/datetimepicker";
import drop from "./components/file/drop";
import elm from "./components/elm";
import gaugechart from "./components/gaugechart";
import matterport from "./components/matterport";
import event from "./tracking/event";
import file from "./forms/file";
import flexiblenav from "./components/flexiblenav";
import ga from "./tracking/ga";
import hubspot from "./tracking/hubspot";
import lightbox from "./components/lightbox";
import modal from "./components/modal";
import nav from "./components/nav";
import notfound from "./tracking/notfound";
import notifications from "./components/notifications";
import order from "./forms/order";
import list from "./suppliers/list";
import plan from "./suppliers/plan";
import reason from "./suppliers/reason";
import pdf from "./components/pdf";
import planbasket from "./components/planbasket";
import posthog from "./tracking/posthog";
import price from "./price";
import proposalcalculator from "./components/proposalcalculator";
import rag from "./components/rag";
import radio from "./forms/radio";
import redirect from "./components/redirect";
import selectAll from "./components/selectAll";
import sidescroller from "./components/sidescroller";
import simplemap from "./components/simplemap";
import stripe from "./assessment/stripe";
import tooltip from "./components/tooltip";
import contentsubmit from "./components/contentsubmit";
import tracking from "./tracking/tracking";
import upload from "./components/upload";
import valuefetching from "./components/valuefetching";
import twin from "./components/twin";
import usage from "./components/usage";
import visibility from "./components/visibility";

Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.data("address", address);
Alpine.data("ajax", ajax);
Alpine.data("assignee", assignee);
Alpine.data("calendar", calendar);
Alpine.data("carousel", carousel);
Alpine.data("contentsubmit", contentsubmit);
Alpine.data("cover", cover);
Alpine.data("datetimepicker", datetimepicker);
Alpine.data("drop", drop);
Alpine.data("elm", elm);
Alpine.data("event", event);
Alpine.data("file", file);
Alpine.data("flexiblenav", flexiblenav);
Alpine.data("ga", ga);
Alpine.data("gaugechart", gaugechart);
Alpine.data("hubspot", hubspot);
Alpine.data("lightbox", lightbox);
Alpine.data("matterport", matterport);
Alpine.data("modal", modal);
Alpine.data("nav", nav);
Alpine.data("notfound", notfound);
Alpine.data("notifications", notifications);
Alpine.data("order", order);
Alpine.data("list", list);
Alpine.data("plan", plan);
Alpine.data("reason", reason);
Alpine.data("planbasket", planbasket);
Alpine.data("posthog", posthog);
Alpine.data("price", price);
Alpine.data("proposalcalculator", proposalcalculator);
Alpine.data("rag", rag);
Alpine.data("exportToPDF", pdf);
Alpine.data("radio", radio);
Alpine.data("redirect", redirect);
Alpine.data("selectAll", selectAll);
Alpine.data("sidescroller", sidescroller);
Alpine.data("simplemap", simplemap);
Alpine.data("stripe", stripe);
Alpine.data("tooltip", tooltip);
Alpine.data("tracking", tracking);
Alpine.data("upload", upload);
Alpine.data("valuefetching", valuefetching);
Alpine.data("twin", twin);
Alpine.data("usage", usage);
Alpine.data("visibility", visibility);

Alpine.start();
